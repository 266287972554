import React from "react";
import Fade from "react-reveal/Fade";
import LayoutPage from "@components/LayoutPage";
import SEO from "@components/seo";
import Work from "@components/work/Work";

function WorkPage() {
  return (
    <LayoutPage>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Portfolio - Olle Burlin"
      />
      <Fade>
        <section className="flex flex-col">
          <Work />
        </section>
      </Fade>
    </LayoutPage>
  );
}

export default WorkPage;
